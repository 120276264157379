<h2 mat-dialog-title>Create Ad</h2>
<form mat-dialog-content novalidate id="create_ad_form" (submit)="submit()">
  <x-dashboard-form-layout [formGroup]="form">
    <x-form-panel>
      <!-- name -->
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" />
      </mat-form-field>
      <!-- url -->
      <mat-form-field>
        <mat-label>Url</mat-label>
        <input matInput type="url" formControlName="url" />
      </mat-form-field>
      <!-- weight -->
      <mat-form-field>
        <mat-label>Weight</mat-label>
        <input matInput type="number" min="0" max="1" step=".1" formControlName="weight" />
      </mat-form-field>
      <!-- target channel -->
      <mat-form-field>
        <mat-label>
          <fa-icon icon="store"></fa-icon>
          Target Channel
        </mat-label>
        <x-data-select
          formControlName="targetChannelId"
          [provider]="Providers.ChannelItemCollectionProvider"
          [page]="{ index: 0, size: 20 }"
        ></x-data-select>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-flat-button type="button" color="accent" (click)="closeDialog()">Cancel</button>
  <button
    mat-flat-button
    type="submit"
    color="primary"
    form="create_ad_form"
    [disabled]="form.invalid"
  >
    Create
  </button>
</div>
