<ng-container xMeta metaTitle="Ad Form"></ng-container>
<x-dashboard-layout-simplex>
  <x-dashboard-toolbar icon="grid-2-plus" header="Ad Form">
    @if (form) {
      @if (form.mediaFormArray.errors) {
        <x-pill color="warn">
          <fa-icon icon="xmark"></fa-icon>
          There are errors in the form
        </x-pill>
      }
    }

    <button
      mat-flat-button
      xToolbarAction
      color="primary"
      (click)="save()"
      [disabled]="uploadQueue$.isLoading || (form?.invalid ?? false)"
    >
      @if (uploadQueue$.isLoading) {
        <fa-icon icon="spinner"></fa-icon>
        Uploading
      } @else {
        <fa-icon icon="save"></fa-icon>
        Save
      }
    </button>
  </x-dashboard-toolbar>

  <x-dashboard-panel position="side">
    <x-form-panel *ngIf="form" [formGroup]="form">
      <!-- Name -->
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" />
      </mat-form-field>

      <!-- Url -->
      <mat-form-field>
        <mat-label>Url</mat-label>
        <input matInput type="url" formControlName="url" />
      </mat-form-field>

      <!-- Url Target -->
      <mat-checkbox class="mb-3" formControlName="openInNewTab">Open in new tab</mat-checkbox>

      <!-- Weight -->
      <mat-form-field>
        <mat-label>Weight</mat-label>
        <input matInput type="number" min="0" max="1" step=".1" formControlName="weight" />
      </mat-form-field>

      <!-- Target Channel -->
      <mat-form-field>
        <mat-label>
          <fa-icon icon="store"></fa-icon>
          Target Channel
        </mat-label>
        <x-data-select
          formControlName="targetChannelId"
          [provider]="Providers.ChannelItemCollectionProvider"
          [page]="{ index: 0, size: 100 }"
        ></x-data-select>
      </mat-form-field>

      <!-- Target Regions -->
      <mat-form-field>
        <mat-label>
          <fa-icon icon="map-marked-alt"></fa-icon>
          Target Regions
        </mat-label>
        <x-data-autocomplete
          formControlName="targetRegionIds"
          [provider]="Providers.GeoRegionItemCollectionProvider"
          [multiple]="true"
        ></x-data-autocomplete>
      </mat-form-field>

      <!-- Target Contextual Taxons -->
      <mat-form-field>
        <mat-label>
          <fa-icon icon="layer-group"></fa-icon>
          Target Contextual Taxons
        </mat-label>
        <x-data-autocomplete
          formControlName="targetTaxonIds"
          [provider]="Providers.TaxonItemCollectionProvider"
          [multiple]="true"
        ></x-data-autocomplete>
      </mat-form-field>

      <!-- Target Children Taxons -->
      <mat-checkbox class="mb-3" formControlName="targetChildrenTaxons">
        Target Children Taxons
      </mat-checkbox>

      <!-- Target Authorization Type -->
      <mat-form-field>
        <mat-label>
          <fa-icon icon="lock"></fa-icon>
          Target Authorization
        </mat-label>
        <x-enum-select
          [nullable]="false"
          formControlName="targetAuthorizationType"
          enum="TargetAuthorizationType"
        ></x-enum-select>
      </mat-form-field>

      <!-- Enabled -->
      <mat-checkbox class="mb-3" formControlName="enabled">Enabled</mat-checkbox>

      <!-- Start & End Dates -->
      <mat-form-field appearance="fill">
        <mat-label>Start and end date</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="startsAt" placeholder="Start date" />
          <input matEndDate formControlName="endsAt" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
        <mat-date-range-picker #picker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Cancel</button>
            <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>

      <!-- Placements -->
      <mat-form-field>
        <mat-label>Placements</mat-label>
        <x-enum-select
          [nullable]="false"
          formControlName="placements"
          multiple
          enum="AdPlacement"
        ></x-enum-select>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-panel>

  <x-dashboard-panel [panelLoading]="uploadQueue$.isLoading">
    @if (form) {
      @if (form.value.placements?.length === 0) {
        <div class="d-flex p-4">
          <fa-icon icon="info-circle" class="pe-2"></fa-icon>
          Select one or more placements from the panel on the left
        </div>
      }
      @for (placement of form.value.placements; track $index) {
        <xd-card>
          <div xdHeader>
            {{ 'AdPlacement.' + placement | l }}
            @if (form.mediaFormArray.getError('mediaType')?.includes(placement)) {
              <x-pill
                color="warn"
                matTooltip="All media within a placement must have the same type: i.e. they must all be images or videos"
              >
                Inconsistent Media Types
              </x-pill>
            }
            <button
              xdHeaderActions
              class="upload-button"
              mat-flat-button
              [matMenuTriggerFor]="placementUploadMenu"
              [matMenuTriggerData]="{
                placement,
                mediaQueries: mediaQueries
              }"
            >
              Change media for all sizes
            </button>
          </div>
          <div class="d-flex flex-row flex-wrap">
            @for (mediaQuery of mediaQueries; track mediaQuery) {
              @if (form.getMediaGroup(placement, mediaQuery); as group) {
                <div
                  class="d-flex flex-column col-6 col-lg-3 d-flex placement-block"
                  [formGroup]="group"
                  [class.has-error]="group.invalid"
                >
                  <x-action-row>
                    <div xActionStart>{{ 'MediaQuery.' + mediaQuery | l }}</div>

                    <button
                      *ngIf="group.value.url"
                      class="upload-button"
                      color="primary"
                      mat-button
                      [matTooltip]="'Change media for ' + ('MediaQueryLabel.' + mediaQuery | l)"
                      [matMenuTriggerFor]="placementUploadMenu"
                      [matMenuTriggerData]="{
                        placement,
                        mediaQueries: [mediaQuery]
                      }"
                    >
                      <fa-icon icon="upload"></fa-icon>
                    </button>
                    <button
                      *ngIf="group.value.url"
                      class="remove-button"
                      color="warn"
                      mat-button
                      matTooltip="Remove media"
                      (click)="form.removeMediaGroup(placement, mediaQuery)"
                    >
                      <fa-icon icon="xmark"></fa-icon>
                    </button>
                  </x-action-row>
                  @if (group.value.url) {
                    <x-media-thumbnail
                      class="media"
                      *ngIf="group.value.url"
                      [showTypeIcon]="true"
                      class="m-2 mt-0"
                      [url]="group.value.url"
                      [height]="300"
                      [width]="300"
                    ></x-media-thumbnail>
                  } @else {
                    <div
                      class="p-5 d-flex justify-content-center align-items-center no-media"
                      (click)="
                        uploadMedia({
                          placement,
                          mediaQueries: [mediaQuery]
                        })
                      "
                    >
                      <fa-icon icon="file-plus"></fa-icon>
                      <span>Click here to add media</span>
                    </div>
                  }

                  <div class="placement-info">
                    @if (group.value.url) {
                      <span [xClip]="group.value.url" [matTooltip]="group.value.url">
                        <b>Url:</b>
                        {{ group.value.url }}
                      </span>
                    } @else {
                      <span>No media added for {{ 'MediaQueryLabel.' + mediaQuery | l }}</span>
                    }
                    @if (group.value.width && group.value.height) {
                      <span>
                        <b>Size:</b>
                        {{ group.value.width }} x {{ group.value.height }} px
                      </span>
                    }
                    @if (group.value.width && group.value.height) {
                      <span>
                        <b>Ratio:</b>
                        {{ calcRatio(group.value.width, group.value.height) }}
                      </span>
                    }
                  </div>
                </div>
              }
            }
          </div>
        </xd-card>
      }
    }
  </x-dashboard-panel>
</x-dashboard-layout-simplex>

<mat-menu #placementUploadMenu>
  <ng-template matMenuContent let-placement="placement" let-mediaQueries="mediaQueries">
    <button
      mat-menu-item
      (click)="
        uploadMedia({
          placement,
          mediaQueries
        })
      "
    >
      <fa-icon icon="cloud-upload-alt"></fa-icon>
      Upload new media
    </button>
    <!-- <button
      mat-menu-item
      (click)="
        selectMedia({
          placement,
          mediaQueries
        })
      "
    >
      <fa-icon icon="plus"></fa-icon>
      Select
    </button> -->
  </ng-template>
</mat-menu>
