import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ChannelItemCollectionProvider } from '@x/ecommerce/domain-data';
import { CreateAdInput } from '@x/schemas/ecommerce';

export class CreateAdFormGroup extends FormGroup {
  constructor() {
    super({
      name: new FormControl<string | undefined>(undefined, [Validators.required]),
      targetChannelId: new FormControl<number | undefined>(undefined, [Validators.required]),
      url: new FormControl<string | undefined>(undefined, [Validators.required]),
      weight: new FormControl<number | undefined>(undefined, [Validators.required]),
      enabled: new FormControl<boolean>(false),
    });
  }

  get formValue(): CreateAdInput {
    const { name, targetChannelId, url, weight, enabled } = this.value;
    return {
      name,
      targetChannelId,
      url,
      weight,
      enabled,
    };
  }
}

@Component({
  selector: 'x-ad-create-dialog',
  templateUrl: './ad-create-dialog.component.html',
})
export class AdCreateDialogComponent {
  readonly Providers = {
    ChannelItemCollectionProvider,
  };

  readonly form = new CreateAdFormGroup();

  constructor(private dialog: MatDialogRef<AdCreateDialogComponent>) {}

  submit() {
    this.form.updateValueAndValidity();

    console.info(this.form);

    if (this.form.invalid) return;

    this.dialog.close(this.form.formValue);
  }

  closeDialog() {
    this.dialog.close();
  }
}
