import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateAdInput } from '@x/schemas/ecommerce';
import { AdCreateDialogComponent } from '../components/ad-create-dialog/ad-create-dialog.component';

@Injectable()
export class AdDialogService {
  constructor(private readonly matDialog: MatDialog) {}

  openCreateDialog() {
    return this.matDialog.open<AdCreateDialogComponent, any, CreateAdInput>(
      AdCreateDialogComponent,
      {
        width: '500px',
      },
    );
  }
}
