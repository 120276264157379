import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdFormComponent } from './components/ad-form/ad-form.component';
import { AdIndexComponent } from './components/ad-index/ad-index.component';

const ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AdIndexComponent,
  },
  {
    path: 'form/:id',
    component: AdFormComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
  declarations: [],
})
export class AdRoutingModule {}
