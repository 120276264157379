import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DashboardCoreModule } from '@x/dashboard/core';
import { DashboardFormModule } from '@x/dashboard/form';
import { DashboardGridModule } from '@x/dashboard/grid';
import { DashboardMediaModule } from '@x/dashboard/media';
import { AdCreateDialogComponent } from './components/ad-create-dialog/ad-create-dialog.component';
import { AdDialogService } from './services/ad-dialog.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DashboardCoreModule,
    DashboardFormModule,
    DashboardGridModule,
    DashboardMediaModule,
  ],
  declarations: [AdCreateDialogComponent],
  providers: [AdDialogService],
  exports: [DashboardMediaModule],
})
export class AdSharedModule {
  static forRoot(): ModuleWithProviders<AdSharedModule> {
    return {
      ngModule: AdSharedModule,
      providers: [AdDialogService],
    };
  }
}
