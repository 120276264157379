import { coerceArray } from '@angular/cdk/coercion';
import { HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataViewFactoryService } from '@x/common/data';
import { OperationObserverService } from '@x/common/operation';
import { DeviceSizes } from '@x/common/responsive';
import { IMAGE_MIMES, VIDEO_MIMES } from '@x/common/utils';
import { PromptDialogService } from '@x/dashboard/dialog';
import { MediaUploadService, MediaUploadState } from '@x/dashboard/media';
import { AdService, IAdDetailObject, IAdMediaObject } from '@x/ecommerce/domain-client';
import {
  AdDetailProvider,
  AdPlacement,
  ChannelItemCollectionProvider,
  GeoRegionItemCollectionProvider,
  TaxonItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { AdMediaInput, TargetAuthorizationType, UpdateAdInput } from '@x/schemas/ecommerce';
import { DateTime } from 'luxon';
import { Subject, lastValueFrom, takeUntil, tap } from 'rxjs';

const gcd = (a: number, b: number): number => (b === 0 ? a : gcd(b, a % b));

const AD_MEDIA_QUERIES: DeviceSizes[] = ['XS', 'SM', 'MD', 'LG'];

export const mediaTypeConsistencyValidator: ValidatorFn = (
  formArray: AbstractControl,
): ValidationErrors | null => {
  const errorPlacements = new Set<string>();

  const placementGroups: { [key: string]: Set<string> } = {};

  (formArray as FormArray).controls.forEach((formGroup: AbstractControl, index: number) => {
    const placement = formGroup.get('placement')?.value;
    const type = formGroup.get('type')?.value;

    if (!placementGroups[placement]) {
      placementGroups[placement] = new Set<string>();
    }
    placementGroups[placement].add(type);
    if (placementGroups[placement].size > 1) {
      errorPlacements.add(placement);
    }
  });

  return errorPlacements.size > 0
    ? {
        mediaType: Array.from(errorPlacements),
      }
    : null;
};

export class AdMediaFormGroup extends FormGroup {
  get placement() {
    return this.controls.placement.value;
  }

  get mediaQuery() {
    return this.controls.mediaQuery.value;
  }

  get formValue(): AdMediaInput | null {
    const { type, url, alt, height, width, originalName, quality } = this.value;
    if (!url) return null;
    return {
      id: this.obj?.id,
      mediaQuery: this.mediaQuery,
      placement: this.placement,
      type,
      url,
      alt,
      height,
      width,
      originalName,
      quality,
    };
  }

  constructor(private readonly obj?: IAdMediaObject) {
    super({
      id: new FormControl<number | null | undefined>(obj?.id),
      type: new FormControl<string | null | undefined>(obj?.type),
      url: new FormControl<string | null | undefined>(obj?.url, [Validators.required]),
      width: new FormControl<number | null | undefined>(obj?.width),
      height: new FormControl<number | null | undefined>(obj?.height),
      originalName: new FormControl<string | null | undefined>(obj?.originalName),
      quality: new FormControl<number | null | undefined>(obj?.quality),
      mediaQuery: new FormControl<string | null | undefined>(obj?.mediaQuery, [
        Validators.required,
      ]),
      placement: new FormControl<string | null | undefined>(obj?.placement, [Validators.required]),
    });
  }

  clear() {
    this.patchValue({
      type: null,
      url: null,
      width: null,
      height: null,
      originalName: null,
      quality: null,
    });
  }
}

export class AdFormGroup extends FormGroup {
  get formValue(): UpdateAdInput {
    const { id } = this.obj;
    const {
      name,
      url,
      openInNewTab,
      weight,
      enabled,
      targetChannelId,
      targetRegionIds,
      targetTaxonIds,
      targetChildrenTaxons,
      targetAuthorizationType,
      startsAt,
      endsAt,
    } = this.value;

    return {
      id,
      name,
      url,
      urlTarget: openInNewTab ? '_blank' : '_self',
      weight,
      enabled,
      targetChannelId,
      targetRegionIds,
      targetTaxonIds,
      targetChildrenTaxons,
      targetAuthorizationType,
      startsAt,
      endsAt,
      media: this.mediaFormArray.controls.map((m) => m.formValue).filter(Boolean) as AdMediaInput[],
    };
  }

  mediaFormArray = new FormArray<AdMediaFormGroup>(
    this.obj.media.map((m) => new AdMediaFormGroup(m)),
    [mediaTypeConsistencyValidator],
  );

  placementsControl = new FormControl<AdPlacement[]>(
    (Array.from(
      new Set(this.obj.media.map((m) => m.placement).filter(Boolean)),
    ) as AdPlacement[]) ?? [],
  );

  constructor(private readonly obj: IAdDetailObject) {
    super({
      name: new FormControl<string | undefined>(obj.name, [Validators.required]),
      url: new FormControl<string | null | undefined>(obj.url, [Validators.required]),
      urlTarget: new FormControl<string>(obj.urlTarget ?? '_self'),
      openInNewTab: new FormControl<boolean>(obj.urlTarget === '_blank'),
      weight: new FormControl<number | undefined>(obj.weight, [Validators.required]),
      enabled: new FormControl<boolean>(obj.enabled ?? false),
      targetAuthorizationType: new FormControl<TargetAuthorizationType>(
        obj.targetAuthorizationType ?? TargetAuthorizationType.Any,
      ),
      targetChannelId: new FormControl<number | undefined>(obj.targetChannel.id, [
        Validators.required,
      ]),
      targetRegionIds: new FormControl<number[]>(obj.targetRegions.map(({ id }) => id)),
      targetTaxonIds: new FormControl<number[]>(obj.targetTaxons.map(({ id }) => id)),
      targetChildrenTaxons: new FormControl<boolean>(obj.targetChildrenTaxons ?? false),
      startsAt: new FormControl<DateTime | null | undefined>(obj.startsAt),
      endsAt: new FormControl<DateTime | null | undefined>(obj.endsAt),
    });
    this.registerControl('placements', this.placementsControl);
    this.registerControl('media', this.mediaFormArray);
  }

  getMediaGroup(placement: AdPlacement, mediaQuery: string) {
    let mediaGroup = this.mediaFormArray.controls.find(
      (c) => c.placement === placement && c.mediaQuery === mediaQuery,
    );

    if (!mediaGroup) {
      mediaGroup = new AdMediaFormGroup();
      mediaGroup.patchValue({
        placement,
        mediaQuery,
      });
      this.mediaFormArray.push(mediaGroup);
    }

    return mediaGroup;
  }

  removeMediaGroup(placement: AdPlacement, mediaQuery: string) {
    const index = this.mediaFormArray.controls.findIndex(
      (c) => c.placement === placement && c.mediaQuery === mediaQuery,
    );

    if (index < 0) return;

    this.mediaFormArray.controls.at(index)?.clear();
    this.mediaFormArray.updateValueAndValidity();
    this.updateValueAndValidity();
  }
}

@Component({
  selector: 'x-ad-form',
  templateUrl: './ad-form.component.html',
  host: {
    class: 'x-ad-form',
  },
})
export class AdFormComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  readonly mediaQueries = AD_MEDIA_QUERIES;

  readonly Providers = {
    ChannelItemCollectionProvider,
    TaxonItemCollectionProvider,
    GeoRegionItemCollectionProvider,
  };

  readonly uploadQueue$ = this.operation.createQueue();
  readonly view = this.dataViewFactory.resolveView(AdDetailProvider);

  mediaUploads: MediaUploadState[] = [];
  form?: AdFormGroup;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackbar: MatSnackBar,
    private readonly prompt: PromptDialogService,
    private readonly dataViewFactory: DataViewFactoryService,
    private readonly adService: AdService,
    private readonly mediaUpload: MediaUploadService,
    private readonly changeRef: ChangeDetectorRef,
    private readonly operation: OperationObserverService,
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.view.setId(Number(id));

      this.view.dataChanges().subscribe((data) => {
        if (data) {
          const form = new AdFormGroup(data);
          this.form = form;

          form.placementsControl.valueChanges
            .pipe(
              takeUntil(this._destroy$),
              tap((p) => {
                form.mediaFormArray.controls.forEach((group) => {
                  if (p?.includes(group.placement)) {
                    group.enable();
                  } else {
                    group.disable();
                  }
                });

                console.log(form);

                this.changeRef.markForCheck();
              }),
            )
            .subscribe();

          this.changeRef.markForCheck();
        }
      });

      this.view.connect();

      this.uploadQueue$.observe().subscribe((upload) => {
        if (upload.isSuccessState()) {
          this.snackbar.open(`${upload.label} Uploaded Succeeded`);
        }
        if (upload.isErrorState()) {
          this.snackbar.open(`${upload.label} Uploaded Failed`);
        }
        this.form?.mediaFormArray.updateValueAndValidity();
        this.changeRef.markForCheck();
      });
    }
  }

  ngOnDestroy(): void {
    this.view.disconnect();
  }

  save() {
    this.form?.updateValueAndValidity();
    if (!this.form || this.form.invalid) return;
    let value = this.form.formValue;

    this.view
      .observeMutation(() => this.adService.update(value), {
        label: 'Updating Ad',
      })
      .pipe(
        tap((o) => {
          if (o.isSuccessState()) {
            this.changeRef.markForCheck();
            this.snackbar.open('Ad Saved');
            this.router.navigate(['/admin/ads']);
          }
          if (o.isErrorState()) {
            this.changeRef.markForCheck();
            this.snackbar.open('An error occurred, the ad could not be saved');
          }
        }),
      )
      .subscribe();
  }

  async uploadMedia({
    placement,
    mediaQueries,
  }: {
    placement: AdPlacement;
    mediaQueries: string | string[];
  }) {
    if (!this.form) return;
    const form = this.form;

    // filter accepted mime types based on placement
    const firstType: 'image' | 'video' | undefined = (
      this.form.mediaFormArray.controls
        .filter((ctrl) => ctrl.placement === placement)
        .find((ctrl) => !!ctrl.value.url) as AdMediaFormGroup | undefined
    )?.controls.type.value;
    const accept: string[] = [];
    if (firstType !== 'image') {
      accept.push(...VIDEO_MIMES);
    }
    if (firstType !== 'video') {
      accept.push(...IMAGE_MIMES);
    }

    const media = await lastValueFrom(
      this.prompt
        .files({
          title: 'Upload Ad Media',
          message: 'Note: This replaces existing media',
          accept,
          multiple: false,
        })
        .afterClosed(),
    );

    if (!media || media.files.length === 0) return;

    this.uploadQueue$.queue(
      this.mediaUpload.upload(media.files[0]).pipe(
        tap((upload) => {
          if (upload.type !== HttpEventType.Response) {
            return;
          }

          coerceArray(mediaQueries).forEach((mediaQuery) => {
            form.getMediaGroup(placement, mediaQuery).patchValue({
              placement,
              mediaQuery,
              ...upload.body,
            });
          });

          form.mediaFormArray.updateValueAndValidity({
            emitEvent: true,
            onlySelf: false,
          });
          this.changeRef.markForCheck();
        }),
      ),
      {
        label: media.files[0].name,
      },
    );
    this.changeRef.markForCheck();
  }

  // async selectMedia({
  //   placement,
  //   mediaQueries,
  // }: {
  //   placement: AdPlacement;
  //   mediaQueries: string | string[];
  // }) {
  //   console.info({ placement, mediaQueries });

  //   if (!this.form) return;
  //   const form = this.form;
  // }

  calcRatio = (a: number | null, b: number | null): string => {
    if (!a || !b) return '-';
    const divisor = gcd(a, b);
    return [a / divisor, b / divisor].join(':');
  };
}
