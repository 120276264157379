<ng-container xMeta metaTitle="Ad Index"></ng-container>
<x-dashboard-layout-simplex>
  <x-dashboard-toolbar icon="grid-2-plus" header="Ads" [subheader]="view.stateTitle ?? 'Index'">
    <x-search-field xToolbarFlex>
      <input [formControl]="searchControl" type="text" matInput placeholder="Search ads..." />
    </x-search-field>

    <button mat-flat-button xToolbarAction color="primary" (click)="openCreateDialog()">
      <fa-icon icon="plus"></fa-icon>
      Add Ad
    </button>
  </x-dashboard-toolbar>

  <x-dashboard-panel position="side" closeable>
    <mat-tab-group xPanelTab>
      <mat-tab label="Views">
        <x-data-table-states *matTabContent [view]="view"></x-data-table-states>
      </mat-tab>
      <mat-tab label="Filter">
        <x-dashboard-form-layout [formGroup]="filterControl">
          <x-form-panel>
            <!-- Target Channel -->
            <mat-form-field>
              <mat-label>
                <fa-icon icon="store"></fa-icon>
                Target Channel
              </mat-label>
              <x-data-select
                formControlName="targetChannelIds"
                [provider]="Providers.ChannelItemCollectionProvider"
                multiple
                [page]="{ index: 0, size: 100 }"
              ></x-data-select>
            </mat-form-field>

            <!-- Target Regions -->
            <mat-form-field>
              <mat-label>
                <fa-icon icon="map-marked-alt"></fa-icon>
                Target Regions
              </mat-label>
              <x-data-autocomplete
                formControlName="targetRegionIds"
                [provider]="Providers.GeoRegionItemCollectionProvider"
                [multiple]="true"
              ></x-data-autocomplete>
            </mat-form-field>

            <!-- Target Contextual Taxons -->
            <mat-form-field>
              <mat-label>
                <fa-icon icon="layer-group"></fa-icon>
                Target Contextual Taxons
              </mat-label>
              <x-data-autocomplete
                formControlName="targetTaxonIds"
                [provider]="Providers.TaxonItemCollectionProvider"
                [multiple]="true"
              ></x-data-autocomplete>
            </mat-form-field>

            <!-- Target Authorization Type -->
            <mat-form-field>
              <mat-label>
                <fa-icon icon="lock"></fa-icon>
                Target Authorization
              </mat-label>
              <x-enum-select
                multiple
                formControlName="targetAuthorizationTypes"
                enum="TargetAuthorizationType"
              ></x-enum-select>
            </mat-form-field>

            <!-- Enabled -->
            <x-boolean-filter-field
              icon="toggle-on"
              formControlName="enabled"
              label="Enabled"
              trueLabel="Only Enabled"
              falseLabel="Only Disabled"
            ></x-boolean-filter-field>
          </x-form-panel>
        </x-dashboard-form-layout>
      </mat-tab>
    </mat-tab-group>
  </x-dashboard-panel>

  <x-dashboard-panel [panelLoading]="view.fetchLoading" [panelError]="view.fetchError">
    <x-data-table [view]="view" [activatable]="true" [selectable]="false">
      <!-- id -->
      <span *xDataColumnDef="'id'; let row">
        {{ row.id }}
      </span>
      <!-- name -->
      <span *xDataColumnDef="'name'; let row">
        {{ row.name }}
      </span>
      <!-- targetChannel -->
      <span *xDataColumnDef="'targetChannel'; let row">
        <x-channel-pill [channel]="row.targetChannel"></x-channel-pill>
      </span>
      <!-- targetRegions -->
      <span *xDataColumnDef="'targetRegions'; let row">
        <x-geo-region-pill
          *ngFor="let region of row.targetRegions"
          [region]="region"
        ></x-geo-region-pill>
      </span>
      <!-- url -->
      <span *xDataColumnDef="'url'; let row">
        <span [xClip]="row.url">{{ row.url }}</span>
        @if (row.urlTarget === '_blank') {
          &nbsp;
          <fa-icon icon="arrow-up-right-from-square" matTooltip="Opens in new window"></fa-icon>
        }
      </span>
      <!-- weight -->
      <span *xDataColumnDef="'weight'; let row">
        <x-pill [text]="row.weight"></x-pill>
      </span>
      <!-- enabled -->
      <span *xDataColumnDef="'enabled'; let row">
        <fa-icon [icon]="row.enabled ? 'check' : 'xmark'"></fa-icon>
      </span>
      <!-- targetAuthorizationType -->
      <span *xDataColumnDef="'targetAuthorizationType'; let row">
        {{ 'TargetAuthorizationType.' + row.targetAuthorizationType | l }}
      </span>

      <!-- timestamps -->
      <span *xDataColumnDef="'timestamps'; let row">
        <x-timestamp-column [row]="row"></x-timestamp-column>
      </span>

      <!-- edit -->
      <a
        x-data-button
        *xDataAction="'edit'; let row; primary: true"
        icon="edit"
        [routerLink]="['/admin/ads/form', row.id]"
      ></a>
      <a
        x-data-button
        *xDataAction="'clone'; let row"
        icon="clone"
        label="Clone"
        (click)="clone(row)"
      ></a>
    </x-data-table>
    <x-data-table-controls xPanelFooter [view]="view"></x-data-table-controls>
  </x-dashboard-panel>

  <x-dashboard-panel
    closeable
    [closed]="!(view.displayOptions?.showQuickView ?? false)"
    position="side-m"
    (closeChange)="showQuickViewControl.setValue(!$event.isClosed)"
    [panelError]="detailView.fetchError"
    [panelLoading]="detailView.fetchLoading"
  >
    @if (detailView.data; as data) {
      <!-- name -->
      <xd-list-item icon="grid-2-plus">
        <span xdHeadline>Ad Detail</span>
        <span xdLine>{{ data.name }}</span>
        <span xdLine>
          <span [xClip]="data.url">{{ data.url }}</span>
        </span>
        <span xdLine>
          Weight:
          {{ data.weight }}
        </span>
        <span xdLine>
          Enabled:
          <fa-icon [icon]="data.enabled ? 'check' : 'xmark'"></fa-icon>
        </span>
      </xd-list-item>
      <mat-divider></mat-divider>
      <xd-list-item icon="store">
        <span xdHeadline>Target Channel</span>
        <span xdLine>{{ data.targetChannel.name }}</span>
      </xd-list-item>
      <mat-divider></mat-divider>
      <xd-list-item icon="lock">
        <span xdHeadline>Target Regions</span>
        <span xdLine *ngFor="let region of data.targetRegions">
          {{ region.name }}
        </span>
      </xd-list-item>
      <mat-divider></mat-divider>
      <xd-list-item icon="lock">
        <span xdHeadline>Target Authorization</span>
        <span xdLine>{{ 'TargetAuthorizationType.' + data.targetAuthorizationType | l }}</span>
      </xd-list-item>
      <mat-divider></mat-divider>
      <!-- formats -->
      <xd-card appearance="none">
        <span xdHeader>
          <fa-icon icon="image-polaroid"></fa-icon>
          Image Formats
        </span>
        <table class="xd-table row-components-table p-2" style="border-top: 0">
          <tbody>
            <tr>
              <th>Placement</th>
              <th>Size</th>
              <th>Image</th>
            </tr>
            @if (getMedia(data.media); as medias) {
              @if (medias.length) {
                @for (media of medias; track $index) {
                  <tr>
                    <td valign="middle">{{ 'AdPlacement.' + media.placement | l }}</td>
                    <td valign="middle">{{ 'MediaQueryLabel.' + media.mediaQuery | l }}</td>
                    <td valign="middle">
                      <x-media-thumbnail
                        [width]="64"
                        [height]="64"
                        [url]="media.url"
                      ></x-media-thumbnail>
                    </td>
                  </tr>
                }
              } @else {
                <tr>
                  <td colspan="3" align="center">- no media formats -</td>
                </tr>
              }
            }
          </tbody>
        </table>
      </xd-card>
    }
  </x-dashboard-panel>
</x-dashboard-layout-simplex>
