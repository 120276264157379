import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  DataDisplayControl,
  DataFilterControl,
  DataSearchControl,
  DataViewFactoryService,
} from '@x/common/data';
import { OperationCancelledState, OperationSuccessState } from '@x/common/operation';
import { AdService, IAdMediaObject, IAdRowObject } from '@x/ecommerce/domain-client';
import {
  AdDetailProvider,
  AdRowCollectionProvider,
  ChannelItemCollectionProvider,
  GeoRegionItemCollectionProvider,
  TaxonItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { Subject, from, of, startWith, switchMap, takeUntil, tap } from 'rxjs';
import { AdDialogService } from '../../services/ad-dialog.service';
import { AD_TABLE_OPTIONS } from './ad-table-options';

@Component({
  selector: 'x-ad-index',
  templateUrl: './ad-index.component.html',
})
export class AdIndexComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  readonly Providers = {
    ChannelItemCollectionProvider,
    TaxonItemCollectionProvider,
    GeoRegionItemCollectionProvider,
  };

  readonly searchControl = new DataSearchControl();

  readonly filterControl = new DataFilterControl({
    targetChannelIds: new FormControl(),
    targetRegionIds: new FormControl(),
    targetTaxonIds: new FormControl(),
    targetAuthorizationTypes: new FormControl(),
    enabled: new FormControl(),
  });

  readonly view = this.dataViewFactory.resolveTableView(AdRowCollectionProvider, {
    ...AD_TABLE_OPTIONS,
    enableStorage: true,
    displayOptions: {
      showQuickView: false,
    },
    searchControl: this.searchControl,
    filterControl: this.filterControl,
  });

  readonly detailView = this.dataViewFactory.resolveView(AdDetailProvider);
  readonly showQuickViewControl = new FormControl(false);
  readonly displayGroup = new DataDisplayControl(this.view, {
    showQuickView: this.showQuickViewControl,
  });

  constructor(
    private readonly router: Router,
    private readonly changeRef: ChangeDetectorRef,
    private readonly dataViewFactory: DataViewFactoryService,
    private readonly adDialogs: AdDialogService,
    private readonly adService: AdService,
  ) {}

  ngOnInit(): void {
    this.view
      .activeChanges()
      .pipe(startWith(this.view.getActive()), takeUntil(this._destroy$))
      .subscribe((c) => {
        this.detailView.setId(this.view.getActive());
      });

    this.detailView.connect();
    this.detailView.stateChanges().subscribe(() => this.changeRef.markForCheck());
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  openCreateDialog() {
    this.view
      .mutate(() =>
        this.adDialogs
          .openCreateDialog()
          .afterClosed()
          .pipe(
            switchMap((input) =>
              input ? this.adService.create(input) : of(new OperationCancelledState({ input })),
            ),
          ),
      )
      .pipe(
        tap((s) => {
          if (s instanceof OperationSuccessState) {
            this.router.navigate(['/admin/ads/form', s.data.id]);
          }
        }),
      )
      .subscribe();
  }

  getMedia(items: IAdMediaObject[]) {
    return items
      .toSorted((a, b) => (a.placement?.length ?? 0) - (b.placement?.length ?? 0))
      .toSorted((a, b) => (a.mediaQuery.length ?? 0) - (b.mediaQuery.length ?? 0));
  }

  clone({ id }: IAdRowObject) {
    this.view
      .mutate(() =>
        this.adService
          .clone({
            id,
            media: true,
            targetContext: true,
          })
          .pipe(switchMap((res) => from(this.router.navigate(['/admin/ads/form', res.id])))),
      )
      .subscribe();
  }
}
