import type { IDataTableViewOptions } from '@x/common/data';

export const AD_TABLE_OPTIONS: IDataTableViewOptions = {
  id: 'ad_index',
  columns: [
    {
      id: 'id',
      title: 'Id',
      sortable: true,
    },
    {
      id: 'name',
      title: 'Name',
      sortable: true,
    },
    {
      id: 'targetChannel',
      title: 'Target Channel',
    },
    {
      id: 'targetRegions',
      title: 'Target Regions',
    },
    {
      id: 'url',
      title: 'Url',
    },
    {
      id: 'weight',
      title: 'Weight',
      sortable: true,
    },
    {
      id: 'enabled',
      title: 'Enabled',
    },
    {
      id: 'targetAuthorizationType',
      title: 'Target Authorization',
    },
    {
      id: 'timestamps',
      title: 'Timestamps',
    },
  ],
  defaultColumns: ['id', 'name', 'channel', 'url', 'weight', 'enabled', 'targetAuthorizationType'],
  pageSizes: [50, 100, 200],
  page: { index: 0, size: 50 },
};
