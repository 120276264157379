import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OperationModule } from '@x/common/operation';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { AdRoutingModule } from './ad-routing.module';
import { AdSharedModule } from './ad-shared.module';
import { AdFormComponent } from './components/ad-form/ad-form.component';
import { AdIndexComponent } from './components/ad-index/ad-index.component';

@NgModule({
  declarations: [AdFormComponent, AdIndexComponent],
  imports: [
    CommonModule,
    EcommerceAdminCoreModule,
    AdSharedModule,
    AdRoutingModule,
    OperationModule,
  ],
})
export class AdModule {}
